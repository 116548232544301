export const theme = {
  color: {
    orange: "#ba7226",
    yellow: '#b19d2d',
    red: '#c25026',
    green: '#448735',
    white: '#fafafa',
    text: '#222222',
  },
  logoHeight: '54px',
  diceSide: '20px'
};