export const SongList = [  
  'About a girl',
  'Born to be wild',
  'Creep',
  'Jolene',
  'Lake Of Fire',
  'Personal Jesus',
  'Rape Me',
  'Seven Nation Army',
  'The man who sold the world',
  'Where did you sleep last night',
  'Zombie',
  'Bad romance',
  'Love Buzz',
    /*
  'Abou a girl',
  'Born to be ild',
  'Crep',
  'olene',
  'Lake Fire',
  'Persnal Jesus',
  'Rape dMe',
  'Seven Natin Army',
  'The mawho sold the world',
  'Where dd you sleep last night',
  'Zombi',
  'Bad omance',
  'Lover Buzz',
  'Like a pirgin',
  'About a gir',
  'Born to be ssswild',
  'Creefsdsp',
  'JolenaFDe',
  'Lake Of SFDSFFire',
  'Personal JesuSFSDFs',
  'Rape MAAAe',
  'Seven Natdfdion Army',
  'The man who gdgsold the world',
  'Where did you ssdfsleep last night',
  'Zombisse',
  'Bad romdffance',
  */
];